@tailwind base;
@tailwind components;
@tailwind utilities;

.success {
  color: green;
}

.error {
  color: red;
}

.rct-node {
  margin-top: 4px;
}

/* @media print {
  @page {
    size: A4;
    margin: 5mm 0mm 5mm 0mm;
  }

  table {
    page-break-inside: auto;
    margin-bottom: 60px;
  }
  thead {
    background-color: rgb(224, 242, 254) !important;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .page-split {
    page-break-before: always;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
} */

@layer base {
  @font-face {
    font-family: 'Noto Sans KR';
    src: url('/src/assets/fonts/NotoSansKR-Regular.otf') format('opentype');
  }

  * {
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    /* color: #334155; */
    line-height: 16px;
  }

  body {
    print-color-adjust: exact;
  }
}

@layer components {
  /* button */
  .btn-md {
    @apply px-3 py-[0.4rem] text-sm  text-white bg-blue-500 rounded-[0.2rem] cursor-pointer;
  }

  /*  하위 정리  */
  /* Login */
  .w-full-input {
    @apply my-3 bg-secondary-50 border border-secondary-300  rounded-lg block w-full p-2.5 focus:outline-none focus:ring-1 focus:ring-primary-400;
  }

  .tab {
    @apply inline-block px-2 py-3 mx-2 text-secondary-500;
  }

  .tab-active {
    @apply font-semibold border-b-4 bg-purple-50 rounded-t-md text-primary-600 border-primary-500;
  }

  .tab-blue-active {
    @apply font-semibold text-blue-600 border-b-4 border-blue-500;
  }

  .dropdown-menu {
    @apply absolute bg-white border shadow-xl w-60 top-14 right-5 rounded-xl border-secondary-200;
  }

  /* css */
  .dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 500ms ease;
  }

  .dropdown-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 500ms ease;
  }

  .dropdown-menu ul li:hover img {
    opacity: 1;
    cursor: pointer;
  }

  .dropdownItem img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: var(--speed);
  }

  .dropdownItem a {
    max-width: 100px;
    margin-left: 10px;
    transition: var(--speed);
  }

  .cell-yellow {
    @apply bg-yellow-100;
  }

  tspan {
    font-size: 11px;
    font-weight: 300;
  }

  table {
    width: 100%;
  }

  div[role='menu'] {
    visibility: hidden;
  }

  div[role='menu'].visible {
    visibility: visible;
  }

  .custom-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #d1d5db;
  }

  input.input-box {
    border-color: #d1d5db;
  }

  input.input-box:disabled {
    /* background-color: #e5e7eb; */
    background-color: #f0f0f0;
    cursor: not-allowed;
  }

  .custom-input-editing {
    border-color: #2563eb;
    border-width: 1px;
    border-style: solid;
    text-align: right;
    width: 140px;
    padding-right: 45px;
  }

  .custom-input-not-editing {
    border-color: #d1d5db;
    border-width: 1px;
    border-style: solid;
    text-align: right;
    width: 140px;
    padding-right: 45px;
  }

  .slap-table td,
  .slap-table th {
    @apply border border-gray-300 p-2;
  }

  .slap-table th {
    @apply bg-blue-100 border-gray-300 font-bold;
  }

  .slap-table tr.row-total {
    @apply bg-gray-100;
  }

  .slap-table td:has(.editable):hover {
    @apply bg-blue-50;
  }

  .slap-table-form input,
  .slap-table-form textarea {
    @apply border-secondary-300 p-[0.4rem] text-sm border rounded-lg focus:outline-none w-full;
  }

  .slap-table-form textarea {
    @apply h-16;
  }

  .slap-table-form input:read-only {
    @apply border-0 text-base;
  }

  .slap-table-form input.error,
  .slap-table-form textarea.error {
    @apply border-red-500;
  }

  .slap-table-form select {
    @apply border rounded-lg border-secondary-300 p-[0.4rem] text-sm focus:outline-none w-full;
  }

  .slap-table.slap-table-trend {
    @apply w-full border-separate border-spacing-0 border-t;
  }

  .slap-table.slap-table-trend th,
  .slap-table.slap-table-trend td {
    @apply p-1 border-0 border-b border-r min-w-[120px];
  }

  .slap-table.slap-table-trend th.border-left,
  .slap-table.slap-table-trend td.border-left {
    @apply border-l;
  }

  .slap-table.slap-table-trend th.sticky {
    @apply p-1 bg-blue-200;
  }

  .slap-table.slap-table-trend td.sticky {
    @apply p-1 bg-gray-50;
  }

  .slap-table.slap-table-trend th,
  .slap-table.slap-table-trend td,
  .slap-table.slap-table-trend td div {
    @apply text-sm;
  }

  .slap-table-form th {
    @apply bg-white;
  }

  .slap-table-form th.thick {
    @apply bg-blue-100 font-bold;
  }

  .slap-table-form-new th.thick {
    @apply bg-blue-50 font-bold;
  }

  .slap-stat.error {
    @apply text-red-600;
  }

  .slap-stat.warning {
    @apply text-blue-700;
  }

  .slap-stat.ok {
    @apply text-green-600;
  }

  .slap-stat.excluded {
    @apply text-gray-400;
  }

  .menuText:hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: #f3f4f6;
    border-radius: 3px;
    /* opacity: 0.3; */
    z-index: -1;
    /* transition: all 5s ease; */
  }
}
