.popup-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  /* background-color: transparent; */
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.3);
  /* background-color: transparent; */
}

.react-confirm-alert-blur {
  filter: none;
}

.custom-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  min-width: 400px;

  /* width: 400px;
  height: 200px;
  text-align: center;
  color: white; */
}

.custom-ui button {
  border-radius: 0.5rem;
  width: 70px;
  /* width: 150px;
  height: 2rem;
  margin: 1rem; */
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;

  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16
  px */;
}

/* alert body design */
/* .react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
    color: #666;

} */
