/* stepnav */
#stepNav div {
  position: relative;
  line-height: 25px;
  border: 1px solid #ccc;
  background-color: white;
  color: #6b7280;
  cursor: pointer;
}

#stepNav div.right:after {
  content: '';
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 23px;
  height: 23px;
  position: absolute;
  right: -2px;
  top: 0px;
  background-color: white;
  z-index: 150;
  transform: translate(10px, 4px) rotate(45deg);
}

#stepNav div.left:before {
  content: '';
  /* border-top: 1px solid #ccc;
    border-right: 1px solid #ccc; */
  /* width: 29px;
    height: 29px; */
  width: 23px;
  height: 23px;
  position: absolute;
  left: -2px;
  top: 0px;
  background-color: white;
  z-index: 50;
  transform: translate(-10px, 4px) rotate(45deg);
}

#stepNav .active {
  background-color: #6366f1;
  color: white;
}

#stepNav div.active:after {
  background-color: #6366f1;
}

#stepNav div.active:before {
  background-color: #6366f1;
}
