.modal-detail {
    @apply p-7 h-screen w-[600px] bg-white shadow-xl right-[-600px] absolute focus-visible:outline-0 overflow-y-auto overscroll-contain
}

.modal-detail-overlay {
    transition: transform 0.5s ease;
    @apply fixed top-0 left-0 right-0 bottom-0 bg-transparent
}

.modal-detail-overlay.ReactModal__Overlay--after-open {
    transform: translateX(-600px)
}

.modal-detail-overlay.ReactModal__Overlay--before-close {
    transform: translateX(600px);
}

.modal-contents {
  overflow: auto;
  word-break: break-all;
  top: 0px;
  padding-bottom: 10px;
  height: calc(100vh - 10rem);
}

.modal-contents::-webkit-scrollbar {
  width: 10px;
}

.modal-contents::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.modal-contents::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
