.App {
  font-family: sans-serif;
  text-align: center;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 0;
}

/* .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 600px;
} */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  /* overflow: auto; */
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.modal.deny {
  padding: 0;
}

.sm {
  min-width: 350px;
}

.md {
  max-height: 70%;
  min-width: 35%;
}

.xl {
  width: 90%;
  height: 90%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 10;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
