.accordion {
  padding: 20px 0;
  width: 100%;
}

details[open] summary ~ * {
  animation: open 0.5s ease-in-out;
}

@keyframes open {
  0% {
    opacity: 0;
    margin-top: -20px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.arrow {
  transition: transform 0.3s;
  width: 10px;
  height: 10px;
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  transform: rotate(45deg);
  cursor: pointer;
}

details[open] .arrow {
  transform: rotate(135deg);
}
