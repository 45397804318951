.popup-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  /* background-color: transparent; */
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.3);
  /* background-color: transparent; */
}

.react-confirm-alert-blur {
  filter: none;
}
