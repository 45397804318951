/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

.slak-table th {
    @apply border border-gray-300 p-2 text-center font-bold bg-blue-100 whitespace-nowrap
}

.slak-table tr {
    @apply even:bg-gray-50
}

.slak-table td {
    @apply border p-2 text-center  whitespace-nowrap text-clip
}

.slak-table-trend {
    @apply w-full border-separate border-spacing-0 border-t
}

.slak-table-trend th {
    @apply border border-gray-300 p-2 text-center font-bold bg-blue-100 whitespace-nowrap
}

.slak-table-trend td {
    @apply border p-2 whitespace-nowrap text-clip
}

.slak-table-trend th,
.slak-table-trend td {
    @apply border-0 border-b border-r
}

.slak-table-simple th {
    @apply border border-gray-300 p-2 text-center font-bold bg-blue-100 whitespace-nowrap
}

.slak-table-simple tr {
    /*@apply even:bg-gray-50*/
}

.slak-table-simple td {
    @apply border p-2 whitespace-nowrap text-clip
}

.data-table-detail {
    @apply h-screen w-[600px] bg-white shadow-xl right-[-600px] absolute focus-visible:outline-0 overflow-y-auto overscroll-contain
}

.data-table-detail-overlay {
    transition: transform 0.5s ease;
    @apply fixed top-0 left-0 right-0 bottom-0 bg-transparent
}

.data-table-detail-overlay.ReactModal__Overlay--after-open {
    transform: translateX(-600px)
}

.data-table-detail-overlay.ReactModal__Overlay--before-close {
    transform: translateX(600px);
}

/*.ReactModal__Overlay {*/
/*    !*opacity: 0;*!*/
/*    !*transition: opacity 500ms ease-in-out;*!*/
/*    transition: transform 0.5s ease;*/
/*}*/

/*.ReactModal__Overlay--after-open{*/
/*    !*opacity: 1;*!*/
/*    !*left: 0*!*/
/*    !*right: 0;*!*/
/*    transform: translateX(-600px);*/
/*}*/

/*.ReactModal__Overlay--before-close{*/
/*    !*opacity: 0;*!*/
/*    transform: translateX(600px);*/
/*}*/